'use client'

import React, { useEffect, useState } from 'react'

const Loader = (props) => {
	const { height, width, fixed = true } = props

	useEffect(() => {
		setHeightAndWidth()
	}, [height])

	const setHeightAndWidth = () => {
		if (height) {
			document.getElementsByClassName('ball-grid-pulse')[0].style.height = height
			document.getElementsByClassName('ball-grid-pulse')[0].style.width = 'auto'
		} else if (width) {
			document.getElementsByClassName('ball-grid-pulse')[0].style.width = width
			document.getElementsByClassName('ball-grid-pulse')[0].style.height = 'auto'
		}
	}

	return fixed ? (
		<div className='fixed w-full h-full bg-[var(--dark-theme-primary)] z-50 flex justify-center items-center'>
			<div className='ball-grid-pulse aspect-square'>
				<div className='bg-white'></div>
				<div className='bg-white'></div>
				<div className='bg-white'></div>
				<div className='bg-white'></div>
				<div className='bg-white'></div>
				<div className='bg-white'></div>
				<div className='bg-white'></div>
				<div className='bg-white'></div>
				<div className='bg-white'></div>
			</div>
		</div>
	) : (
		<div className={`ball-grid-pulse p-1`}>
			<div className='bg-[var(--primary-reverse)]'></div>
			<div className='bg-[var(--primary-reverse)]'></div>
			<div className='bg-[var(--primary-reverse)]'></div>
			<div className='bg-[var(--primary-reverse)]'></div>
			<div className='bg-[var(--primary-reverse)]'></div>
			<div className='bg-[var(--primary-reverse)]'></div>
			<div className='bg-[var(--primary-reverse)]'></div>
			<div className='bg-[var(--primary-reverse)]'></div>
			<div className='bg-[var(--primary-reverse)]'></div>
		</div>
	)
}

export default Loader
