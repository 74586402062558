import React, { useEffect, useState } from 'react'
import styles from './button.module.css'
import uuid from 'react-uuid'
import { hexToHSL, rgbToHSL } from '../../../helpers/colorConvert'
import Loader from '../Loader/Loader'
import { useRef } from 'react'

const Button = (props) => {
	const {
		onClick,
		type,
		sending,
		color = null,
		colorHover = null,
		textColor = null,
		useFocus = false,
		disableElevation = false,
		className,
		fontSize = null,
		pill = false,
		disabled = false,
		variant = '',
		stretch = false,
	} = props
	const [id, setId] = useState(null)
	const [backgroundColor, setBackgroundColor] = useState('hsl(209, 78%, 46%)')
	const [backgroundHover, setBackgroundHover] = useState('hsl(211, 80%, 41%)')
	const [textColorState, setTextColorState] = useState('#fff')
	const [buttonHeight, setButtonHeight] = useState('')

	const ref = useRef(null)

	useEffect(() => {
		setId(uuid())

		if (color && color[0] === '#') {
			setBackgroundColor(hexToHSL(color.replace(/\s+/g, '')))
		} else if (color && color.substring(0, 3) === 'rgb') {
			setBackgroundColor(rgbToHSL(color.replace(/\s+/g, '')))
		} else {
			setBackgroundColor(color)
		}

		if (textColor && textColor[0] === '#') {
			setTextColorState(hexToHSL(textColor.replace(/\s+/g, '')))
		} else if (textColor && textColor.substring(0, 3) === 'rgb') {
			setTextColorState(rgbToHSL(textColor.replace(/\s+/g, '')))
		}
	}, [])

	useEffect(() => {
		if (disabled) {
			const root = document.querySelector(':root')
			const disabledColor = getComputedStyle(root).getPropertyValue('--disabled')
			setBackgroundColor(disabledColor)
		} else {
			if (color && color[0] === '#') {
				setBackgroundColor(hexToHSL(color.replace(/\s+/g, '')))
			} else if (color && color.substring(0, 3) === 'rgb') {
				setBackgroundColor(rgbToHSL(color.replace(/\s+/g, '')))
			} else {
				setBackgroundColor(color)
			}
		}
	}, [disabled])

	useEffect(() => {
		setButtonHeight(`${ref.current.offsetHeight}px`)
	}, [ref])

	useEffect(() => {
		const firstParenthesisIndex = backgroundColor.indexOf('(')
		const firstCommaIndex = backgroundColor.indexOf(',')
		const firstPercentIndex = backgroundColor.indexOf('%')
		const lastCommaIndex = backgroundColor.lastIndexOf(',')
		const lastPercentIndex = backgroundColor.lastIndexOf('%')

		const firstVal = backgroundColor.substring(
			firstParenthesisIndex + 1,
			firstCommaIndex
		)
		const secondVal = backgroundColor.substring(
			firstCommaIndex + 1,
			firstPercentIndex
		)
		const thirdVal = backgroundColor.substring(
			lastCommaIndex + 1,
			lastPercentIndex
		)
		const newColor = `hsl(${parseInt(firstVal) + 2}, ${
			parseInt(secondVal) + 2
		}%, ${parseInt(thirdVal) - 5}%)`
		setBackgroundHover(newColor)
	}, [backgroundColor, disabled])

	const createRipple = (event) => {
		const button = event.currentTarget

		const circle = document.createElement('span')
		const diameter = Math.max(button.clientWidth, button.clientHeight)
		const radius = diameter / 2

		circle.style.width = circle.style.height = `${diameter}px`
		circle.style.left = `${event.clientX - button.offsetLeft - radius}px`
		circle.style.top = `${
			event.clientY - button.getBoundingClientRect().y - radius
		}px`
		circle.classList.add(`${styles.ripple}`)

		const ripple = button.getElementsByClassName('ripple')[0]

		if (ripple) {
			ripple.remove()
		}

		button.appendChild(circle)
	}

	const handleClick = (e) => {
		createRipple(e)
		if (onClick) {
			onClick()
		}
	}

	return (
		<button
			className={`${styles.contactButton} button ${
				pill ? 'rounded-full' : 'rounded'
			}`}
			onClick={(e) => handleClick(e)}
			onFocus={() => {
				// if (useFocus) setFocus(true)
			}}
			onBlur={() => {
				// if (useFocus) setFocus(false)
			}}
			ref={ref}
			type={type ?? ''}
			id={id}
			disabled={disabled}
		>
			{sending ? (
				<div className={`absolute left-0 right-0 top-0 m-auto w-fit`}>
					<Loader fixed={false} height={buttonHeight} />
				</div>
			) : null}
			<div className={`${sending ? 'opacity-0' : ''}`}>{props.children}</div>
			<style jsx>{`
				.button {
					background: ${variant !== 'outline' ? backgroundColor : 'transparent'};
					box-shadow: ${!disableElevation && !disabled && variant !== 'outline'
						? '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
						: 'none'};
					font-size: ${fontSize ?? 'inherit'};
					border: ${variant === 'outline' ? '2px solid ' + backgroundColor : 'none'};
					cursor: ${disabled ? 'default' : 'pointer'};
					width: ${stretch ? '100%' : 'auto'};
				}
				.button:hover,
				.button:focus {
					background: ${variant !== 'outline'
						? colorHover
							? colorHover
							: backgroundHover
						: backgroundColor};
				}
				.button:hover > div,
				.button:focus > div {
					color: ${textColorState} !important;
				}
				.button div {
					color: ${variant !== 'outline'
						? textColorState
						: backgroundColor} !important;
					transition: all 200ms ease-in-out;
				}
			`}</style>
		</button>
	)
}

export default Button
