import React from 'react'
import './Footer.css'
import { useAppContext } from '../../../utils/context/AppContext'

const Footer = () => {
	const { isMobile } = useAppContext()

	return (
		<div className='footer-section'>
			<div className='footer-cover'>
				<div>
					<div className='footer-circle'>
						<img
							src='/assets/images/gw-motorsports-logo-dark.png'
							alt='GW Motorsports LLC Speed Shop Logo'
							width={isMobile ? 94 : 144}
							className='footer-logo'
						/>
						<div className='phone-number contact-info'>
							<div className='icon-back'>
								<img src='/assets/images/phone-call.png' alt='phone' width={22} />
							</div>
							<a href='tel:9452862506'>(945) 286-2506</a>
						</div>
						<div className='email contact-info'>
							<div className='icon-back'>
								<img src='/assets/images/email.png' alt='phone' width={22} />
							</div>
							<a href='mailto:glenn@gwmotorsportsllc.com'>
								glenn@gwmotorsportsllc.com
							</a>
						</div>
						<div className='address contact-info'>
							<div className='icon-back'>
								<img src='/assets/images/map.png' alt='phone' width={22} />
							</div>
							<a
								href='https://maps.app.goo.gl/V7jHRdJzzZMiNd16A'
								target='_blank'
								rel='noreferrer'
							>
								313 Panorama Loop <br />
								Waxahachie, TX 75165
							</a>
						</div>
					</div>
				</div>
				<div className='owner'>
					<h1>Glenn Wright</h1>
					<span>Owner</span>
				</div>
				<div className='copy'>
					<p>
						GW Motorsports © 2024. All rights reserved.
						<br />
						Website created by{' '}
						<a
							href='https://fennerstudios.com/web-dev'
							rel='noreferrer'
							target='_blank'
							style={{ color: '#00b2ff', cursor: 'pointer' }}
						>
							Fenner Studios
						</a>
						.
					</p>
				</div>
			</div>
		</div>
	)
}

export default Footer
