import React from 'react'
import './About.css'
import Heading from '../../common/heading/Heading'
import { useAppContext } from '../../../utils/context/AppContext'

const About = () => {
	const { isMobile } = useAppContext()

	return (
		<div className={`about-section text-block ${isMobile ? '' : 'p-12'}`}>
			<Heading>About Us</Heading>
			<p>
				GW Motorsports LLC is now proudly in our 3rd year of serving customers and
				performance car enthusiasts in the DFW area and nationwide.
			</p>
			<p>
				Our full time technicians take pride in their work and are experts in their
				specialized fields of installation, fabrication, and tuning. This experience
				shows in our ability to build a proven reliable combination to meet your
				unique goals.
			</p>
			<p className='emphasis'>
				Quality and attention to detail is the #1 priority which is reflected in
				every vehicle that leaves our speed shop.
			</p>
			<p>
				You will find that our pricing is competitive with other local shops, though
				you have the added value of knowing your car is in the hands of
				professionals. GW Motorsports LLC. is an extremely well equipped shop that
				will be there to handle all of your maintenance and performance needs in the
				future. Please take these words into consideration when choosing a shop
				rather than basing your decision purely on cost.
			</p>
		</div>
	)
}

export default About
