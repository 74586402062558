import React from 'react'
import './Heading.css'
import { useAppContext } from '../../../utils/context/AppContext'

const Heading = ({ children }) => {
	const { isMobile } = useAppContext()

	return (
		<div>
			<h2 className={isMobile ? '' : 'text-4xl'}>{children}</h2>
			<div className='underline'></div>
		</div>
	)
}

export default Heading
