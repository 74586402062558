export const hexToHSL = (hex) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
	console.log(result)

	let r = parseInt(result[1], 16)
	let g = parseInt(result[2], 16)
	let b = parseInt(result[3], 16)((r /= 255))((g /= 255))((b /= 255))
	const max = Math.max(r, g, b)
	const min = Math.min(r, g, b)
	let h,
		s,
		l = (max + min) / 2

	if (max == min) {
		h = s = 0 // achromatic
	} else {
		const d = max - min
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0)
				break
			case g:
				h = (b - r) / d + 2
				break
			case b:
				h = (r - g) / d + 4
				break
			default:
				break
		}
		h = Math.round((h / 6) * 360)
	}

	s = s * 100
	s = Math.round(s)
	l = l * 100
	l = Math.round(l)

	return 'hsl(' + h + ', ' + s + '%, ' + l + '%)'
}

export const rgbToHSL = (r, g, b) => {
	;(r /= 255)((g /= 255))((b /= 255))
	const max = Math.max(r, g, b)
	const min = Math.min(r, g, b)
	let h,
		s,
		l = (max + min) / 2

	if (max == min) {
		h = s = 0 // achromatic
	} else {
		const d = max - min
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0)
				break
			case g:
				h = (b - r) / d + 2
				break
			case b:
				h = (r - g) / d + 4
				break
			default:
				break
		}
		h = Math.round((h / 6) * 360)
	}

	s = s * 100
	s = Math.round(s)
	l = l * 100
	l = Math.round(l)

	return 'hsl(' + h + ', ' + s + '%, ' + l + '%)'
}
