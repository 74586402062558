import React from 'react'
import './Services.css'
import Heading from '../../common/heading/Heading'
import { useAppContext } from '../../../utils/context/AppContext'

const Services = () => {
	const { isMobile } = useAppContext()

	return (
		<div>
			<div className='p-1 text-block'>
				<Heading>Service and Parts Installation</Heading>
			</div>
			<div className='visual'>
				<h3 className='text-bold text-light text-block'>We specialize in</h3>
				<div className='specialties'>
					<div className='specialty-circle'>
						<img src='/assets/images/car-parts.png' alt='' width={52} />
						<span>Parts</span>
					</div>
					<div className='specialty-circle'>
						<img src='/assets/images/car-engine.png' alt='' width={52} />
						<span>Installation</span>
					</div>
					<div className='specialty-circle'>
						<img src='/assets/images/speedometer.png' alt='' width={52} />
						<span>Tuning</span>
					</div>
				</div>
			</div>
			<div className={`p-1 text-block ${isMobile ? '' : 'pb-12'}`}>
				<p>
					Late model drive train conversions on classics, custom wiring, nitrous
					kits, turbo kits, late model domestic performance parts, and supercharger
					systems are also our specialty.{' '}
				</p>
				<p>
					GW Motorsports installs every part under the sun, from an air filter to a
					fully built engine package - we do it all. Our state of the art facility is
					conveniently located in Waxahachie, TX and currently has 4 full service
					bays.
				</p>
			</div>
		</div>
	)
}

export default Services
