import React, { useState } from 'react'
import './Contact.css'
import Heading from '../../common/heading/Heading'
import Button from '../../common/Button/Button'
import emailRegexExample from '../../../helpers/emailRegexExample'
import emailjs from '@emailjs/browser'
import toast from 'react-hot-toast'
import { useAppContext } from '../../../utils/context/AppContext'

const Contact = () => {
	const [name, setName] = useState({
		value: '',
		required: true,
		error: '',
	})
	const [email, setEmail] = useState({
		value: '',
		required: true,
		error: '',
	})
	const [make, setMake] = useState({
		value: '',
		required: false,
		error: '',
	})
	const [model, setModel] = useState({
		value: '',
		required: false,
		error: '',
	})
	const [year, setYear] = useState({
		value: '',
		required: false,
		error: '',
	})
	const [message, setMessage] = useState({
		value: '',
		required: true,
		error: '',
	})
	const [sending, setSending] = useState(false)

	const { isMobile, isValid } = useAppContext()

	const formHasErrors = () => {
		return name.error ||
			!name.value ||
			email.error ||
			!emailRegexExample.test(email.value) ||
			!email.value ||
			message.error ||
			!message.value
			? true
			: false
	}

	const sendEmail = (e) => {
		e.preventDefault()
		let emailMessage

		if (year.value || make.value || model.value) {
			emailMessage += `(${year.value.trim()} ${make.value.trim()} ${model.value.trim()}) `
		}

		emailMessage += `${message.value}`.trim()
		const formInputs = {
			name: name.value,
			email: email.value,
			message: emailMessage,
		}

		if (!isValid) {
			toast.error('Unable to send message, reCAPTCHA failed')
			return
		}
		setSending(true)

		emailjs
			.send(
				process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
				process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID,
				formInputs,
				process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY
			)
			.then((res) => {
				console.log('success')
				setSending(false)
				toast.success('Message sent')
				setName((prev) => ({
					...prev,
					value: '',
				}))
				setEmail((prev) => ({
					...prev,
					value: '',
				}))
				setMake((prev) => ({
					...prev,
					value: '',
				}))
				setModel((prev) => ({
					...prev,
					value: '',
				}))
				setYear((prev) => ({
					...prev,
					value: '',
				}))
				setMessage((prev) => ({
					...prev,
					value: '',
				}))
			})
			.catch((error) => {
				console.log(error)
				setSending(false)
				toast.error('Message failed to send')
			})
	}

	return (
		<div className={`contact-section ${isMobile ? '' : 'p-12'}`} id='contact'>
			<div className='text-block contact-inner'>
				<div className='left'>
					<Heading>Contact Us</Heading>
					<h4>Every vehicle is important to us</h4>
					<p>
						Beginning with a consultation, we will detail a game plan and write up a
						rough estimate for your performance needs. (WE NEED TO SEE EVERY VEHICLE
						PRIOR). We will then ask you to authorize the estimate and included
						liability waivers concerning vehicle release and tuning. We may or may not
						ask for a down-payment to order parts. Labor Rate is $120/hr.
					</p>
					<p>
						Once the project is underway, feel free to reach out to us anytime. We
						will be in contact about any new developments or surprises that may occur.
						Depending on the length of the project, we may request payment in weekly
						installments. At completion of project, we do require payment IN FULL upon
						pick up of your vehicle. There is a 3% fee with any card transaction.
						Please do not hesitate to call, email, or message us with any questions
						you may have. And come back when you’re ready for us to help you plan your
						next project!
					</p>
				</div>
				<form onSubmit={(e) => sendEmail(e)} className='right'>
					<div className='field'>
						<span>Name*</span>
						<input
							maxLength={48}
							value={name.value}
							onChange={(e) =>
								setName((prev) => ({
									...prev,
									value: e.target.value,
								}))
							}
						></input>
					</div>
					<div className='field'>
						<span>Email*</span>
						<input
							maxLength={254}
							value={email.value}
							onChange={(e) =>
								setEmail((prev) => ({
									...prev,
									value: e.target.value,
								}))
							}
						></input>
					</div>
					<div className='field'>
						<span>Make</span>
						<input
							value={make.value}
							onChange={(e) =>
								setMake((prev) => ({
									...prev,
									value: e.target.value,
								}))
							}
						></input>
					</div>
					<div className='field'>
						<span>Model</span>
						<input
							value={model.value}
							onChange={(e) =>
								setModel((prev) => ({
									...prev,
									value: e.target.value,
								}))
							}
						></input>
					</div>
					<div className='field'>
						<span>Year</span>
						<input
							maxLength={4}
							value={year.value}
							onChange={(e) =>
								setYear((prev) => {
									const regex = /^[0-9]*$/
									if (!regex.test(e.target.value)) return prev
									return {
										...prev,
										value: e.target.value,
									}
								})
							}
						></input>
					</div>
					<div className='field'>
						<span>Message*</span>
						<textarea
							rows='6'
							maxLength={1000}
							value={message.value}
							onChange={(e) =>
								setMessage((prev) => ({
									...prev,
									value: e.target.value,
								}))
							}
						></textarea>
					</div>
					<Button
						key={2}
						exact={true}
						type='submit'
						stretch
						color={'hsl(198, 100%, 50%)'}
						disabled={formHasErrors() || sending}
					>
						Send
					</Button>
				</form>
			</div>
		</div>
	)
}

export default Contact
