import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './utils.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Toaster } from 'react-hot-toast'
import { AppContextProvider } from './utils/context/AppContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<AppContextProvider>
		<Toaster
			containerStyle={{ position: 'sticky', top: '56px' }}
			position='top-center'
		/>
		<App />
	</AppContextProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
