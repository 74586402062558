import { createContext, useContext, useEffect, useState } from 'react'

const AppContext = createContext({})

export const AppContextProvider = ({ children }) => {
	const [isMobile, setIsMobile] = useState(false)
	const [isValid, setIsValid] = useState(false)

	useEffect(() => {
		setIsMobile(window.innerWidth <= 756)
		window.addEventListener('resize', () => {
			setIsMobile(window.innerWidth <= 756)
		})
	}, [])

	return (
		<AppContext.Provider
			value={{
				isMobile,
				isValid,
				setIsValid,
			}}
		>
			{children}
		</AppContext.Provider>
	)
}

export const useAppContext = () => useContext(AppContext)
