import './App.css'
import Header from './components/landing/header/Header'
import About from './components/landing/about/About'
import Services from './components/landing/services/Services'
import Contact from './components/landing/contact/Contact'
import Footer from './components/common/footer/Footer'
import { Helmet } from 'react-helmet'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useAppContext } from './utils/context/AppContext'

const getFaviconPath = (isDarkMode = false) => {
	return `/favicon-${isDarkMode ? 'light' : 'dark'}.png`
}

function App() {
	const [faviconHref, setFaviconHref] = useState('/favicon-dark.png')

	const { setIsValid } = useAppContext()

	useEffect(() => {
		// Get current color scheme.
		const matcher = window.matchMedia('(prefers-color-scheme: dark)')
		// Set favicon initially.
		setFaviconHref(getFaviconPath(matcher.matches))
		// Change favicon if the color scheme changes.
		matcher.onchange = () => setFaviconHref(getFaviconPath(matcher.matches))
	}, [faviconHref])

	useEffect(() => {
		const script = document.createElement('script')
		script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`
		script.addEventListener('load', () => {
			window.grecaptcha.ready((_) => {
				window.grecaptcha
					.execute(process.env.REACT_APP_RECAPTCHA_KEY)
					.then(async (token) => {
						console.log(token)
						const url = `https://gw-node.netlify.app/.netlify/functions/api`
						await axios
							.post(url, { token })
							.then((response) => setIsValid(response?.data))
					})
			})
		})
		document.body.appendChild(script)
	}, [])

	return (
		<div className='App'>
			<Helmet>
				<link rel='icon' href={faviconHref} />
			</Helmet>
			<Header />
			<About />
			<Services />
			<Contact />
			<Footer />
			<div
				className='g-recaptcha'
				data-sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
				data-size='invisible'
			></div>
		</div>
	)
}

export default App
