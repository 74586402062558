import React, { useEffect, useState } from 'react'
import './Header.css'
import { useAppContext } from '../../../utils/context/AppContext'

const Header = () => {
	const [isTablet, setIsTablet] = useState(false)

	const { isMobile } = useAppContext()

	useEffect(() => {
		setIsTablet(window.innerWidth <= 1200)
		window.addEventListener('resize', () => {
			setIsTablet(window.innerWidth <= 1200)
		})
	}, [])

	const scroll = () => {
		const contact = document.getElementById('contact')

		contact.scrollIntoView({ behavior: 'smooth' })
	}
	return (
		<div>
			{isTablet ? (
				<div>
					<div className='header'>
						<img
							src='/assets/images/gw-motorsports-logo-dark.svg'
							alt='GW Motorsports LLC Speed Shop Logo'
							width={150}
						/>
						<div className='info'>
							<h1>GW motorsports LLC</h1>
							<h3>Performance Car Shop for Service and Parts Installation</h3>
						</div>
						<div className='cta-wrapper'>
							<button className='cta' onClick={scroll}>
								Contact Us
							</button>
						</div>
					</div>
					<img
						className='header-image'
						src='/assets/images/corvette.jpg'
						alt='Black corvette with engine block in background'
					/>
				</div>
			) : (
				<div>
					<div className='header header-desktop'>
						<div className='left'>
							<div className='circle'>
								<img
									src='/assets/images/gw-motorsports-logo-dark.svg'
									alt='GW Motorsports LLC Speed Shop Logo'
									width={300}
									className='circle-logo'
								/>
							</div>
						</div>
						<div className='header-right'>
							<div className='info'>
								<h1 className='text-6xl'>GW motorsports LLC</h1>
							</div>
							<div className='info'>
								<h3 className='text-3xl'>
									Performance Car Shop for Service and Parts Installation
								</h3>
							</div>
							<div className='cta-wrapper'>
								<button className='cta' onClick={scroll}>
									Contact Us
								</button>
							</div>
						</div>
					</div>
					<img
						className='header-image'
						src='/assets/images/corvette.jpg'
						alt='Black corvette with engine block in background'
					/>
				</div>
			)}
		</div>
	)
}

export default Header
